
import {Route, Routes, Navigate} from "react-router-dom";
import React, { useContext } from 'react';
import MainPage from "../page/MainPage";


const AppRouter = () =>{
const publicRoutes = [
      // {path: '/', element: <Page_Main />},
      {path: '/', element: <MainPage/>},
      {path: '/:reflink', element: <MainPage/>},
     //  {path: '/atm', element: <Page_Launch page = {<Launch_Atm />} />},
     //  {path: '/staking', element: <Page_Launch page = {<Launch_Stacking />} />},
       {path: '*', element: <Navigate to='/' replace/>}
    ]
return(
<>
<Routes>
 {publicRoutes.map((p)=><Route key={publicRoutes.indexOf(p)} path = {p.path} element = {p.element}/>)}
</Routes>
</>
   )
}

export default AppRouter;