import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

/** 
 * Компонент Alert имеет два альтернативных варианта стиля — filled и outlined — которые вы можете установить с помощью variantсвойства. 
 * Свойство severityпринимает четыре значения, представляющие различные состояния 
 * — success(по умолчанию), info, warning и error — с соответствующими комбинациями значков и цветов для каждого:
 * */

const AlertNotification = ({messageAlert, open, setOpen, severity}) => {
  // состояние для управления видимостью уведомления
 // const [open, setOpen] = useState(showAlert);

  const handleOpen = () => {
    setOpen(true);
    // установите таймер для автоматического закрытия уведомления через 3 секунды
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  // Здесь вы можете вызывать handleOpen в ответ на какое-либо событие, например:
   useEffect(() => {
     // что-то произошло, показываем алерт
     setTimeout(() => {
        setOpen(false);
      }, 3000);
   }, [open]); 
   
  return (
    <div>
      <Snackbar open={open}>
        <Alert severity={severity} onClose={() => setOpen(false)} 
        //variant='outlined'
        >
          {messageAlert}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AlertNotification;