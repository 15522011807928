import React from 'react';
import Styles from './RefferalProgram.module.css'

const RefferalProgram = ({reward, discount, url_preffix, userAdress, isConnected, Notification}) => {

    const _ref_code = url_preffix + userAdress

    const copy_ref_code = (ref_code) => {
         if (isConnected) {
            if (reward > 0) {
               navigator.clipboard.writeText(ref_code)
               .then(() => {
                  console.log('Код скопирован!');
                  Notification('Refferal link copied', 'success')
                  // Получилось!
               })
               .catch(err => {
                  Notification('Refferal link not copied', 'error')
                  console.log('Something went wrong', err);
               });  
            } else {               
               Notification('To participate in the referral program you need to buy NFT', 'error')
            }
           
         } else {
            Notification('To copy refferal link, connect your wallet', 'error')
         }   
     }

return (
    <ol className="program">
      <label className={Styles.coronation_label}>REFERRAL PROGRAM</label>
      <p >Discount for your referrals:</p>
      <span>{discount}%</span>
      <p >Referral income:</p>
      <span>{reward}%</span>
      <button className="copy" 
        onClick={event=>copy_ref_code(_ref_code)}      
      >COPY REFERRAL LINK</button>
      <p className="offer">
         <i>If you are an influential person, we can offer an individual referral program, just write<br/><br/></i>
         <a href="mailto:partnership@bullishswap.app">partnership@bullishswap.app</a>
   </p>
   </ol>
    );
};

export default RefferalProgram;