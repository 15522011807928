
import { useEffect, useState } from "react";
import { UserContext} from './contex';
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

function App() {
  
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = process.env.REACT_APP_WALLETCONNECT_ID

// 2. Set chains

const mainnet = {
  chainId:    process.env.REACT_APP_CHAIN_ID,
  name:       process.env.REACT_APP_WALLETCONNECT_NETNAME,
  currency:   process.env.REACT_APP_WALLETCONNECT_CURRENCY,
  explorerUrl:process.env.REACT_APP_WALLETCONNECT_EXPLORER,
  rpcUrl:     process.env.REACT_APP_WALLETCONNECT_RPC
}

// 3. Create modal
const metadata = {
  name:         process.env.REACT_APP_WALLETCONNECT_MODAL_NAME,
  description:  process.env.REACT_APP_WALLETCONNECT_MODAL_DESCRIPTION,
  url:          process.env.REACT_APP_WALLETCONNECT_MODAL_URL,
  icons:       [process.env.REACT_APP_WALLETCONNECT_MODAL_ICON]
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  themeVariables: {
    '--w3m-accent': '#b81f1f'
  }
})

const { address, chainId, isConnected } = useWeb3ModalAccount()
const { walletProvider } = useWeb3ModalProvider()

useEffect(()=>{
  const interval = setInterval(()=>{
   //   console.log('22')
  },20000)
  return() => {
    clearInterval(interval);
 };
},[])

return(
<UserContext.Provider value={{
         address, 
         chainId, 
         isConnected,
         walletProvider,
    }}>
  <BrowserRouter>
    {true && <AppRouter/>}
  </BrowserRouter>
</UserContext.Provider>
  );
}

export default App;
