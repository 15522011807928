import React from 'react';
import { useTimer } from 'react-timer-hook';


function TimerUI({ expiryTimestamp }) {

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
  
  const formatTimer = (value)=>{
    let result = value;
    if(Number(value)<10){
      result = `0${Number(value)}`;
    }
    return result
  }
  return (
      <div  className="home__time"
      //style={{fontSize: '70px', color:'orange', fontSize: '15vw'}}
      >
        <span>{formatTimer(days)}</span>:<span>{formatTimer(hours)}</span>:<span>{formatTimer(minutes)}</span>:<span>{formatTimer(seconds)}</span>
      </div>       

  );
}

export default TimerUI;