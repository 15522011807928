import { UserContext } from '../contex';
import React, { useState ,useEffect, useContext} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { abi_ERC721 } from '../config';
import Cookies from 'js-cookie';
import { BrowserProvider, Contract, formatUnits, isAddress, ethers } from 'ethers'
import Styles from './MainPage.module.css'
import AccordionPrice from './AccordionPrice';
import LeftPrice from './LeftPrice';
import RefferalProgram from './RefferalProgram';
import AlertNotification from '../components/UI/AlertNotification';
import Timer from '../components/Timer';

const contract_ERC721_address = process.env.REACT_APP_ERC721_ADDRESS
const url_preffix = process.env.REACT_APP_REFFERAL_URL_PREFFIX

const MainPage = () => {
const { address, chainId, isConnected, walletProvider} = useContext(UserContext);
const userAdress = address; 
const decimals = 18;
const [refferer, setRefferer] = useState('0x0000000000000000000000000000000000000000');
const { reflink } = useParams();
const navigate = useNavigate();

/** Для компонента уведомления AlertNotification*/
const [messageAlert, setMessageAlert] = useState(false);
const [open, setOpen] = useState(false);
const [severity, setSeverity] = useState(false);
const Notification = (_messageAlert, _severity) =>{
   setMessageAlert(_messageAlert)
   setSeverity(_severity)   
   setOpen(true)
}
const priceFormat = (value) =>{
   let result = 0
   result = (value / 10**18).toFixed(5)
   return String(parseFloat(result))
}
/** Для реферальной системы */
useEffect(() => {
    if (reflink) {
        // Проверяем валидность Ethereum адреса
        if (isAddress(reflink)) {
          // Если валиден, сохраняем в куки
          Cookies.set('reflink', reflink, { expires: 7 }); // Куки сохранятся на 7 дней
          setRefferer(reflink)  
        } else {
          // Если не валиден, обработайте это соответствующим образом
          console.error('Неверный Ethereum адрес');
        }  
        // Скрываем 'reflink' из URL
        navigate('/', { replace: true });
      }
    }, [reflink, navigate]);

useEffect(() => {
// Получение значения куки 'reflink'
const reflink = Cookies.get('reflink');
    if (reflink) {
    // Извлекаем значение 'reflink' для использования
    console.log('Сохраненный в куках reflink:', reflink);
    setRefferer(reflink)    
    } else {
    console.log('Куки с именем reflink не найдены.');
    }
}, [reflink, navigate]);

/** Создаем объекты контрактов если кошелек подключен*/
let contract_ERC721;
const [provider, setProvider] = useState({})
const createContracts = async () =>{
  const ethersProvider =  new BrowserProvider(walletProvider);
  const signer =  await ethersProvider.getSigner();
  contract_ERC721 = new Contract(contract_ERC721_address, abi_ERC721, signer);
 // const provider = new ethers.providers.Web3Provider(walletProvider);
  //console.log('contract_ERC721', contract_ERC721);
 // setProvider(ethersProvider)
}
if (isConnected) { 
   createContracts();      
} 

useEffect(()=>{    
    const interval = setTimeout(() => {  
      
      }, 1000);   
    return (clearInterval(interval))
},[isConnected])

/** Выполняем переодические запросы чтобы видеть балансы и апрув */
const [publicClaim, setPublicClaim] = useState(false)
const [publicSale, setPublicSale] = useState(false)
const [whitelistMinted, setWhitelistMinted] = useState(0)
const [rewardsPersent, setRewardsPersent] = useState(0)
const [discountPersent, setDiscountPersent] = useState(0)
const [totalSupply, setTotalSupply] = useState(0)
const [currentPrice, setCurrentPrice] = useState(0)
const [currentPriceFull, setCurrentPriceFull] = useState(0)
const [maxAmount, setMaxAmount] = useState(0)
const [etherBalance, setEtherBalance] = useState('0')

useEffect(()=>{
    const requests = async () =>{
        if (true && isConnected) {    
          try {
            const _currentPriceFull = await contract_ERC721.getCurrentSumm('1', '0x0000000000000000000000000000000000000000')
            //console.log('_currentPriceFull', _currentPriceFull)
            setCurrentPriceFull(Number(_currentPriceFull).toLocaleString('fullwide', {useGrouping:false}))

            const _currentPrice = await contract_ERC721.getCurrentSumm('1', refferer)
            //console.log('_currentPrice', _currentPrice)
            setCurrentPrice(Number(_currentPrice).toLocaleString('fullwide', {useGrouping:false}))
            //console.log('currentPrice', currentPrice)            

            const _publicClaim = await contract_ERC721.publicClaim()  
            //console.log('_publicClaim', _publicClaim)
            setPublicClaim(Boolean(_publicClaim))

            const _publicSale = await contract_ERC721.publicSale()  
            //console.log('_publicSale', _publicSale)
            setPublicSale(Boolean(_publicSale))
            
            const _whitelistMinted = await contract_ERC721.whitelistMinted(userAdress)  
            //console.log('_whitelistMinted', _whitelistMinted)
            setWhitelistMinted(Number(_whitelistMinted))

            const _rewardsPersent = await contract_ERC721.rewardsPersent(userAdress)  
            //console.log('_rewardsPersent', _rewardsPersent)
            setRewardsPersent(Number(_rewardsPersent))

            const _discountPersent = await contract_ERC721.discountPersent(userAdress)  
            //console.log('_discountPersent', _discountPersent)
            setDiscountPersent(Number(_discountPersent))

            const _totalSupply = await contract_ERC721.totalSupply()  
            //console.log('_totalSupply', _totalSupply)
            setTotalSupply(String(_totalSupply))
            /** получаем и рассчитываем максимум оставший для минта данному юзеру */
            const _publicMinted = await contract_ERC721.publicMinted(userAdress)  
            //console.log('_publicMinted', _publicMinted)
            const _maxPublicMinted = await contract_ERC721.maxPublicMinted() 
            //console.log('_maxPublicMinted', _maxPublicMinted)
            const numberEnabled = Number(_maxPublicMinted) - Number(_publicMinted)
            if (numberEnabled>0) {
               setMaxAmount(numberEnabled)
            } else {
               setMaxAmount(0)
            }
            try {
            // getBalance возвращает Promise, который исполняется в BigInt (баланс в wei)
          //  const provider = new ethers.providers.Web3Provider(walletProvider);
         //   const balanceWei = await provider.getBalance(userAdress);
           // setEtherBalance(String(balanceWei))
            //console.log(`Баланс: ${balanceWei} wei`);
            } catch (error) {
               console.log(error);
            }
           
          } catch (error) {            
          }
        }}
     // слушаем  апрув токена контракту стейкинга
       requests()
  
     const interval = setInterval(() =>{  
        requests()
     }, 2500); 
     // Функция очистки, вызываемая при размонтировании компонента
     return () => {
        clearInterval(interval);
     };
   },[userAdress])

/** Работа с ценой для отправки транзакции минта */   
const [amount, setAmount] = useState(0)
const [currentSumm, setCurrentSumm] = useState(0)

useEffect(()=>{
  const requests = async () =>{
    if (true && isConnected) {    
      try {
        const _currentSumm = await contract_ERC721.getCurrentSumm(amount, refferer)
      //  console.log('_currentSumm', _currentSumm)
        setCurrentSumm(Number(_currentSumm).toLocaleString('fullwide', {useGrouping:false}))
      //  console.log('currentSumm', currentSumm)
      } catch (error) {
      }
    }}
    // слушаем  сумму для данного размера покупки и реферера
    requests()
    const interval = setInterval(() =>{  
      requests()
    }, 1000); 
    // Функция очистки, вызываемая при размонтировании компонента
    return () => {
      clearInterval(interval);
    };
  },[userAdress, amount])

const mint = async () => {
    try {
     // проверяем баланс
     try {
      const ethersProvider =  new BrowserProvider(walletProvider);
      //console.log(`ethersProvider`, ethersProvider);
      const balanceWei = await ethersProvider.getBalance(userAdress);
        // setEtherBalance(String(balanceWei))
      console.log(`Баланс: ${balanceWei} wei`);
      if (currentSumm > balanceWei){
         //console.log(priceFormat(balanceWei), priceFormat(currentSumm));
         console.log(balanceWei, currentSumm);
         Notification(`Insufficient balance for purchase. You need for purchase:  ${priceFormat(currentSumm)} ETH`,  'error')
      }    
     } catch (error) {
      console.log(error);
     }
  
      if (maxAmount>0 && maxAmount>=amount && amount>0) {
         console.log('mint')
         const send_amount = amount
         const tx = await contract_ERC721.mint(
           amount, refferer,
           { //  value: purchaseAmount,gasLimit: _gaslimit, gasPrice: gasPrice, gasPrice: ethers.utils.parseUnits(_gasprice, "gwei"),
             value: currentSumm
           }
         );
         console.log('\x1b[32m%s\x1b[32m\x1b[0m','Stake tx send: '+ tx.hash)
         const receiptet = await tx.wait();
         console.log('\x1b[32m%s\x1b[32m\x1b[0m','Stake tx confirmed: '+ receiptet.transactionHash);
         if(receiptet){
            Notification(`${send_amount} NFT successfully minted`, 'success')
         }         
      } else if( amount == 0) {
          Notification('Enter a number greater than zero', 'error')
      } else {
         if (maxAmount>0  && maxAmount < amount) {
            Notification('Specify a smaller number of tokens for mint', 'error')
         } else {
            Notification('This wallet cannot mint more tokens', 'error')
         }
      }
    } catch (error) {
      console.log(error)
    }
}
const _set_count_dogs = (_value) =>{
  let value = Number(_value)
  console.log(value)
  if (maxAmount >= value &&  value > 0 ) {
    setAmount(value)
  } else {
    maxAmount < value?
    setAmount(maxAmount) : setAmount(0) 
  }
  console.log(amount)
}   
/** Изза того что блок с подлкючением кнопки внизу страницы а библиотека web3modal при попытке подключения переводит фокус вверх страницы,
 * то после подключения возвращаем страничку к блоку минта */
useEffect(()=>{
   if (isConnected) {
      const element = document.getElementById('coronation'); // Замените `your-element-id` на ID вашего элемента
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
   }
},[isConnected])

/** Раскрывающийся список цен */
// Создаём состояние isOpen для отслеживания того, открыт компонент или нет
const [isOpenPrice, setIsOpenPrice] = useState(false);
// Функция для переключения состояния isOpen
const toggleAccordionPrice = () => {
   setIsOpenPrice(!isOpenPrice);
};
/** Раскрывающийся блок реферальной программы */
const [isOpenReff, setIsOpenReff] = useState(false);
// Функция для переключения состояния isOpen
const toggleAccordionReff = () => {
   setIsOpenReff(!isOpenReff);
};


return (
<>
<AlertNotification messageAlert={messageAlert} severity ={severity}  open ={open} setOpen  ={setOpen} />
<h3 onClick={event=>Notification('messageAlert', 'success')}
>The coronation <br/> of the Bulls: <br/> {totalSupply} / 10000</h3>
<div className="coronation__flex">
   <LeftPrice/>
   <div className="calculation">
      <div>
         <picture>
            {false && <source srcset="img/coronation-Img.webp" type="image/webp"/>}
            <img className="main-img" src="img/coronation-Img.png" alt=""/>
         </picture>
         <div className="input">
            <button className="minus" onClick={event => _set_count_dogs(Number(amount)-1)}>
            <img loading="lazy" src="img/icons/minus.svg" alt=""/>
            </button>
            <input type="number"
            placeholder="max 3 NFTs"// min="1" max="3"
            onChange = {event => _set_count_dogs(event.target.value)}
            value = {amount}
            />
            <button className="plus" onClick={event => _set_count_dogs(Number(amount)+1)}>
               <img loading="lazy" src="img/icons/plus.svg" alt=""/>
            </button>
         </div>
         {isConnected &&
         <p className="offer"  style = {{marginTop:'0px'}}>
         <i > Available {maxAmount} for this wallet</i>
         </p>}
         
      </div>
         {isConnected ?
            <w3m-account-button balance ={'show'}/>
            :
            <w3m-connect-button onClick = {event => event.preventDefault()}  style = {{fontSize:'30px',marginTop: '0px'}} label={'CONNECT WALLET'}/> 
         }
         {
         isConnected ?          
             publicSale? 
            <div  className={[Styles.button, "_button"].join(' ')}  onClick={event=>mint()}>
               CORONATION
            </div>
            : 
            <p className="offer"  style = {{marginTop:'10px'}}>
               <i>START IN: </i><Timer/>
            </p>        
         :
         <></>
         }
      <p className="offer" style = {{marginTop:'10px'}}>
         <i>Current price: {priceFormat(currentPrice)}&nbsp;ETH&nbsp;
         {Number(currentPrice) != Number(currentPriceFull) &&
         <>
         <span 
         className={Styles.currentPriceFull}
         //style={{ textDecorationLine: 'line-through'}} 
         >{priceFormat(currentPriceFull)} ETH</span>
         &nbsp;</>
         }         
         </i><br/>
         <i>Current total: {priceFormat(currentSumm)} ETH</i><br/>
         <a href="terms.html" target="_blank">Terms &amp; Conditions</a>
      </p>
   </div>
   <RefferalProgram 
      reward = {rewardsPersent} 
      discount ={discountPersent} 
      url_preffix = {url_preffix} 
      userAdress = {userAdress} 
      isConnected  = {userAdress} 
      Notification = {Notification} />
   <div className="accordion" data-accordion data-width="10000">
      <div className="accordion__item" data-accordion-item
       {...(isOpenReff && {'data-open': 'open'})}
       //data-open="open" 
       onClick={event => toggleAccordionReff()}
      >
         <div className="accordion__item-title" data-accordion-title>
            <label >Referral program</label>
            <button>
            <img loading="lazy" src="img/icons/arrow-down.svg" alt=""/>
            </button>
         </div>
         <div className="accordion__item-content" data-accordion-content>
            <div className="accordion__item-content-height">
               <div>
                <RefferalProgram 
                  reward = {rewardsPersent} 
                  discount ={discountPersent} 
                  url_preffix = {url_preffix} 
                  userAdress = {userAdress} 
                  isConnected  = {userAdress} 
                  Notification = {Notification}/>
               </div>
            </div>
         </div>
      </div>
      <div className="accordion__item" data-accordion-item 
      {...(isOpenPrice && {'data-open': 'open'})}
      //data-open="open" 
      onClick={event => toggleAccordionPrice()}
      >
         <div className="accordion__item-title" data-accordion-title>
            <label>PRICE CHART</label>
            <button>
            <img loading="lazy" src="img/icons/arrow-down.svg" alt=""/>
            </button>
         </div>
         <div className="accordion__item-content" data-accordion-content>
            <AccordionPrice/>
         </div>
      </div>
   </div>
</div>
</>
    );
};

export default MainPage;