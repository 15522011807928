import React from 'react';
import TimerUI from './TimerUI';

const Timer = () => {
   /** таймер */   
   const timerSettings = JSON.parse(process.env.REACT_APP_TIMER)
   let start_year = timerSettings[0];
   let start_month = timerSettings[1];
   // день старта продаж
   let start_day = timerSettings[2];
  // старт продаж в часах EST
   let start_hours = timerSettings[3];
   let start_minutes = timerSettings[4]
   let start_seconds = timerSettings[5] 
   // смещение целевого пояса  EST относительно UTC в часах
   let est_offset = -4; 
   // возвращает смещение часового пояса относительно часового пояса UTC в минутах для текущей локали
   let x = new Date();
   let currentTimeZoneOffsetInHours = x.getTimezoneOffset() / 60;
   // итоговое время
   let hours = start_hours + est_offset - currentTimeZoneOffsetInHours
   /**  */

   // так как таймер корректно отрабатывает даже отрицательное значение часов то доп.манипуляций не нужно
   let time = new Date(start_year, start_month, start_day, hours, start_minutes, start_seconds, 0);
   //год месяц день час минута секунда милисекунда
    //  console.log(currentTimeZoneOffsetInHours)
    //  console.log(hours)
    return (
<>
<TimerUI
 expiryTimestamp={time}
/>
</>
    );
};

export default Timer;