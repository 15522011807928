import React from 'react';

const AccordionPrice = () => {
    return (
<div className="accordion__item-content-height">
   <div>
      <ol className="chart">
         <li>
            <p>0001-1000 = 0.015</p>
            <span>ETH</span>
         </li>
         <li>
            <p>1001-2000 = 0.025</p>
            <span>ETH</span>
         </li>
         <li>
            <p>2001-3000 = 0.035</p>
            <span>ETH</span>
         </li>
         <li>
            <p>3001-4000 = 0.05</p>
            <span>ETH</span>
         </li>
         <li>
            <p>4001-5000 = 0.1</p>
            <span>ETH</span>
         </li>
         <li>
            <p>5001-6000 = 0.2</p>
            <span>ETH</span>
         </li>
         <li>
            <p>6001-7000 = 0.3</p>
            <span>ETH</span>
         </li>
         <li>
            <p>7001-8000 = 0.4</p>
            <span>ETH</span>
         </li>
         <li>
            <p>8001-9000 = 0.5</p>
            <span>ETH</span>
         </li>
         <li>
            <p>9001-10000 = 0.6</p>
            <span>ETH</span>
         </li>
      </ol>
   </div>
</div>
    );
};

export default AccordionPrice;