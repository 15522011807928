import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
/** раскоментить в продакшене чтобы в консоль не сыпалась инфа */
if (process.env.REACT_APP_CONSOLE_LOG == "false") {
    console.log = () => {}
    console.error = () => {}
} 
root.render(
    <App />
);

